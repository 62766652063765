import React from 'react'
// import ResumeBtn from "../Components/ResumeBtn"

import image from "../assets/intro.png"
import '../styles/intro.css'

const Intro = () => {
    return(
        <div id="intro" className="hero">
            <div className="hero-body container">
            <div className="columns">
                <div className="column col-6 col-md-12 introcontent">
                    <h1>Hi, I'm Roger!</h1>
                    <p>I'm currently working on this site. Coming soon!
                    </p>
                    {/* <ResumeBtn/> */}
                </div>
                {/* <div className="column col-3 col-md-12 filler"></div> */}
                <div className="column col-6 col-md-12 introimgcontainer">
                    <img id="introimg" className="" src={image} alt="Introduction, me waving"></img>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Intro