import React from 'react'
import { Link } from 'react-scroll';
import '../styles/navbar.css'

const NavBar = ({setActive}) => {
    const menuClick = () => {
        setActive(true)
    }
    //might need isDynamic in case page size changes
    return(
        <header className="navbar">
            <section className="navbar-section">
                <h3 className="navbar-brand mr-2">Roger</h3>
                <button className="off-canvas-toggle show-sm btn btn-action" onClick={menuClick}>
                    <i className="icon icon-menu"></i>
                </button>
            </section>
            <section className="navbar-section hide-sm">
                <Link className="btn btn-link" activeClass="active" to="about" smooth={true} duration={800}>
                    About
                </Link>
                <Link className="btn btn-link" activeClass="active" to="experience" smooth={true} duration={800}>
                    Experiences
                </Link>
                <Link className="btn btn-link" activeClass="active" to="projects" smooth={true} duration={800}>
                    Projects
                </Link>
                <Link className="btn btn-link" activeClass="active" to="education" smooth={true} duration={800}>
                    Education
                </Link>
                <Link className="btn btn-link" activeClass="active" to="footer" smooth={true} duration={800}>
                    Contact
                </Link>
            </section>
        </header>
    )
}

export default NavBar