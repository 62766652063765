import React, { useState } from 'react'
import NavBar from '../Components/Navbar'
import Intro from '../pages/intro'
// import Education from '../pages/education'
// import About from '../pages/about'
// import Experience from '../pages/experience'
// import Projects from '../pages/projects'
// import Footer from '../pages/footer'
// import ToTop from '../Components/ToTop'
import OffCanvas from '../Components/OffCanvas'

import '../styles/home.css'

const Home = () => {
    let [active, setActive] = useState(false)

    const menuClick = () => {
        setActive(false)
    }

    return (
        <div className="off-canvas">
            <div id="sidebar-id" className={"off-canvas-sidebar"+(active?" active":"")}>
                <OffCanvas />
            </div>

            <button className="off-canvas-overlay c-hand" onClick={menuClick}></button>

            <div className="off-canvas-content home">
                <NavBar setActive={setActive}/>
                <Intro />
                {/* <About />
                
                <Experience />
                <Projects />
                <Education />
                <Footer />
                <ToTop offcanActive={active}/> */}
            </div>
        </div>
    );
}

export default Home;
