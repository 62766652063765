import React from 'react';
import Home from './pages/home';

import "./styles/colours.css"

const App = () => {
    return (
        <Home/>
    )
}

export default App;
