import React from 'react'
import { Link } from 'react-scroll';
import '../styles/offcanvas.css'

const OffCanvas = () => {
    //might need isDynamic in case page size changes
    return(
        <ul className="nav">
            <li className="nav-item">
                <Link className="btn btn-link" activeClass="active" to="about" smooth={true} duration={800}>
                    About
                </Link>
            </li>
            <li className="nav-item">
                <Link className="btn btn-link" activeClass="active" to="experience" smooth={true} duration={800}>
                    Experiences
                </Link>
            </li>
            <li className="nav-item">
                <Link className="btn btn-link" activeClass="active" to="projects" smooth={true} duration={800}>
                    Projects
                </Link>
            </li>
            <li className="nav-item">
                <Link className="btn btn-link" activeClass="active" to="education" smooth={true} duration={800}>
                    Education
                </Link>
            </li>
            <li className="nav-item">
                <Link className="btn btn-link" activeClass="active" to="footer" smooth={true} duration={800}>
                    Contact
                </Link>
            </li>
        </ul>
    )
}

export default OffCanvas